@import '../../../../assets/scss/variables';

#form-dialog-title {
  background-color: $dark-sub-grey;
  color: $white;
}

#form-dialog-title > button {
  background-color: $white;
  color: $dark-sub-grey;
  border: 1px solid white;
  padding: 2px;
  top: 16px;
  right: 26px;
}
