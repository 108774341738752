@import '../../../assets/scss/variables';

#form-dialog-title {
  background-color: $dark-sub-grey;
  color: $white;
}

.MuiDialogContent-root:first-child {
  text-align: center;
  padding: 10% 0;
}

.successIcon {
  margin-bottom: 3%;
  margin-top:5%;
}

.successTitle {
  margin-bottom: 2%;
}

.closeButton{
  width: 28%;
  font-weight: bold !important;
  padding: 3% !important;
  margin-top: 50px !important;
  text-transform: capitalize !important;
}