.container {
  width: 100%;
  height: 100%;
  padding: 1rem;
}
.submit-group {
  display: flex;
  justify-content: flex-end;
}

.error-message {
  color: red;
  font-size: 13px;
}