@import '/src/assets/scss/variables';

.btn {
  height: 2.9rem !important;
}

.btn:disabled {
  background-color: #a8a8a8 !important;
}

.submit {
  padding: 1rem 2rem !important;
  min-width: 10rem;
}

.cancel {
  border: 1px solid $primary !important;
  color: $primary !important;
  padding: 1rem 2rem !important;
}

.add {
  padding: 1rem 3rem !important;
}

@mixin color {
  background-color: $primary !important;
  color: $white !important;
}

.primary {
  @include color;
  &:hover {
    @include color;
  }
}

@mixin dark {
  background-color: #808080 !important;
  color: $white !important;
}
.dark {
  @include dark;
  &:hover {
    @include dark;
  }
}

@mixin seondary {
  background-color: $secondary !important;
  color: $white !important;
}

.secondary {
  @include seondary;
  &:hover {
    @include seondary;
  }
}
